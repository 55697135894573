import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xSLIPjreZL from "/usr/src/app/node_modules/nuxt-meta-pixel/dist/runtime/plugin.client.mjs";
import plugin_client_UYiXMU8ZyN from "/usr/src/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_gab9g3XuEx from "/usr/src/app/node_modules/nuxt-aos/dist/runtime/plugin.js";
import bootstrap_client_Uyd73t1ylZ from "/usr/src/app/plugins/bootstrap.client.ts";
import cookieconsent_client_k0DKrALfet from "/usr/src/app/plugins/cookieconsent.client.ts";
import error_handler_kEP5FliEXj from "/usr/src/app/plugins/error-handler.ts";
import imagehelper_hZHWffqDPr from "/usr/src/app/plugins/imagehelper.ts";
import markdownit_fsFV40uZ9C from "/usr/src/app/plugins/markdownit.ts";
import vue_awesome_paginate_rvI9ZlY63V from "/usr/src/app/plugins/vue-awesome-paginate.ts";
import wow_client_utB3fCnsxi from "/usr/src/app/plugins/wow.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_xSLIPjreZL,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_gab9g3XuEx,
  bootstrap_client_Uyd73t1ylZ,
  cookieconsent_client_k0DKrALfet,
  error_handler_kEP5FliEXj,
  imagehelper_hZHWffqDPr,
  markdownit_fsFV40uZ9C,
  vue_awesome_paginate_rvI9ZlY63V,
  wow_client_utB3fCnsxi
]